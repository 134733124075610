import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CookieConsent from './components/common/cookieconsent';

import App from '@/App';
import Pricing from '@/pages/pricing';
import PrivacyPolicy from '@/pages/privacy-policy';
import ResourceLibrary from '@/pages/resources';
import BlogList from '@/pages/blog';
import Mobile from '@/pages/mobile';
import ContactPage from '@/pages/contact';
import ProfilePage from '@/pages/ralphbrooks'; // Import ProfilePage component

const modules = import.meta.glob([
  './resources/**/*.tsx',
  './events/**/*.tsx',
  './customer-stories/**/*.tsx',
  './community/**/*.tsx',
  './demo/**/*.tsx',
  './solutions/**/*.tsx',
  './blog/**/*.tsx',
]);

// Generate route objects from the modules
const routes = Object.keys(modules).map((modulePath) => {
  // Get the route path from the module path
  let routePath = modulePath
    .replace('./', '/') // Remove './' at the beginning
    .replace(/\.tsx$/, '') // Remove file extension
    .replace(/\/index$/, '') // Remove '/index' at the end
    .toLowerCase(); // Optional: convert to lowercase

  // Replace [param] with :param for dynamic routes
  routePath = routePath.replace(/\[(.+?)\]/g, ':$1');

  // Lazy load the component
  const Component = lazy(modules[modulePath] as () => Promise<{ default: React.ComponentType<any> }>);

  return {
    path: routePath,
    Component,
  };
});

const MainRouter = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {/* If you have specific routes that need to be defined first, add them here */}
        {/* Example: <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<App />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/resources" element={<ResourceLibrary />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/mobile" element={<Mobile />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/ralphbrooks" element={<ProfilePage />} /> {/* Add this route */}
        {routes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Routes>
    </Suspense>
    <CookieConsent />
  </Router>
);

export default MainRouter;
