
import SEO from '../components/common/seo/SEO';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull'; // Import AppHeaderFull

const ContactPage = () => {
  return (
    <>
      <SEO
        title='Contact Us'
        description="Here is our contact information. Call us to place an order." 
        includeOrg={true}
      />
      <div className="p-8 bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col">
        {/* Header Section */}
        <header className=" fixed top-0 left-0 right-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />  {/* Use AppHeaderFull for desktop */}
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <a href="/" className="text-[#FFFFFC] text-xl font-bold mr-auto">Forte Notary</a>
            <AppHeaderMobile />  {/* Use AppHeaderMobile for mobile */}
          </div>
        </header>
        {/* Main Content Section */}
        <main>
          <div className="flex-grow flex mt-12 justify-center">
            <div className="bg-[#212A37] p-8 max-w-lg w-full">
              <h1 className="text-3xl font-bold mb-6">Contact Us</h1>
              <h2>If you need a DFW notary right now, give me a call or text me.</h2>
              <p className="mt-8">Ralph Brooks can be reached at </p>
              <p><a href="tel:+14692968682" className="text-[#e2ba57] ">+1 (469) 296-8682</a></p>
              <p className="mt-4 mb-4">If email works better for you, I can be reached at 
                <a href="mailto:ralph.brooks@fortenotary.com" className="text-[#e2ba57] "> ralph.brooks@fortenotary.com</a>

              </p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ContactPage;
