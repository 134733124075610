import SEO from '../components/common/seo/SEO';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull'; // Import AppHeaderFull

const Pricing = () => {
  return (
    <>
      <SEO
        title='Pricing'
        description="Notarization made easy with simple pricing. We are a mobile notary based out of Frisco, TX." 
        includeOrg={false}
      />
      <div className="pricing-page p-8 bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col">
        {/* Header Section */}
        <header className=" fixed top-0 left-0 right-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />  {/* Use AppHeaderFull for desktop */}
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <a href="/" className="text-[#FFFFFC] text-xl font-bold mr-auto">Forte Notary</a>
            <AppHeaderMobile />  {/* Use AppHeaderMobile for mobile */}
          </div>
        </header>
        {/* Main Content Section */}
        <div className="flex-grow flex items-center justify-center">
          <div className="pricing-card bg-[#212A37] p-8 max-w-lg w-full  ">
            <h1 className="text-3xl font-bold mb-6">Make your notarization easy</h1>
            <div className="pricing-tier bg-[#2A3445] p-8 rounded-3xl shadow-xl max-w-lg w-full border border-[#3A475C]">
              <h2 className="text-3xl font-semibold mb-4">$50 travel fee + $10 for 1 signature (Collin County & Dallas County only)</h2>
              <p className="mb-4">The quickest way to get your documents notarized.</p>
              <ul className="list-none">
                <li className="mb-2 flex items-center">
                  <span className="mr-2 text-[#FFFFFC]">✔</span>
                  <span className="underline-dotted"><strong>Mobile service:</strong> We come to your location, saving you time and hassle.</span>
                </li>
                <li className="mb-2 flex items-center">
                  <span className="mr-2 text-[#FFFFFC]">✔</span>
                  <span className="underline-dotted"><strong>Simple, transparent pricing:</strong> No hidden fees, just a flat travel fee and per-signature rate.</span>
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-[#FFFFFC]">✔</span>
                  <span className="underline-dotted"><strong>Flexible scheduling:</strong> Available for appointments when you need us.</span>
                </li>
              </ul>
            </div>


          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
