import { useState } from 'react';
import { css, keyframes } from '@emotion/css';
import VideoEmbed from './video/VideoEmbed';

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const YoutubeStyle = css`
  position: relative;
  width: 100%;
  max-width: 850px;
  height: 478px;
  margin: 16px auto;

  .placeholder,
  .videoembedstyle {
    position: absolute; /* Change from absolute to relative */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Set z-index lower than header */
  }

  .videoembedstyle {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    /* Remove z-index if present */
  }

  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1E293B;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    /* Remove z-index if present */
  }

  .play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    background-color: #e2ba57;
    border-radius: 8px;
  }

  .play-icon {
    width: 0;
    height: 0;
    border-left: 15px solid white;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  .fade-out {
    animation: ${fadeOut} 0.5s forwards;
  }

  .before-video-fade-in {
    opacity: 0;
  }

  .fade-in {
    animation: ${fadeIn} 0.5s forwards;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .YoutubeStyle {
      /* height: 60vh; */
      margin-top: 0;
      border-radius: 0;
    }
  }

  /* Laptop and larger screens */
  @media (min-width: 768px) {
    .YoutubeStyle {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

interface YoutubePlaceholderProps {
  videoId: string;
}

const YoutubePlaceholder: React.FC<YoutubePlaceholderProps> = ({ videoId }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <div className={YoutubeStyle}>
      <div className="video-container">
        <div
          className={`placeholder ${isPlaying ? 'fade-out' : ''}`}
          onClick={handlePlayVideo}
        >
          <div className="play-button">
            <div className="play-icon"></div>
          </div>
        </div>
        <div
          className={`videoembedstyle ${isPlaying ? 'fade-in' : 'before-video-fade-in'}`}
          style={{ pointerEvents: isPlaying ? 'auto' : 'none' }}
        >
          <VideoEmbed videoId={videoId} />
        </div>
      </div>
    </div>
  );
};

export default YoutubePlaceholder;

