// app/frontend/src/components/common/cookieconsent.tsx

import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookiesAccepted');
    if (!consent) {
      setIsVisible(true);
    } else {
      // Initialize Google Analytics if consent was previously given
      // initializeAnalytics();
      initializeGTM();
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
    // initializeAnalytics();
    initializeGTM();
  };

  const initializeGTM = () => {
    // console.log('Initializing GTM');

    if (document.getElementById('gtm-script')){
      // console.log('GTM script already inserted');
      return; // Prevent duplicate script insertion
    } 
  
    // Insert GTM Head Script
    const script = document.createElement('script');
    script.id = 'gtm-script';
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PJCMZVZT');
    `;
    document.head.appendChild(script);
  
    // Insert GTM Body Noscript (Optional, for no-JS support)
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJCMZVZT"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.insertAdjacentElement('afterbegin', noscript);
  };

  return (
    <motion.div
      initial={{ y: '100%' }}
      animate={{ y: isVisible ? 0 : '100%' }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className="fixed bottom-0 left-0 right-0 p-4 bg-[#2A3445] text-white flex justify-between items-center z-50 shadow-lg"
    >
      <p className="text-sm">
        We use cookies to improve your Website experience, provide additional security, and remember you when you return to the Website. By continuing to use this Website, you agree to the use of cookies. See our
        <a href="/privacy-policy" className="underline ml-2">
          Privacy Policy
        </a>
        .
      </p>
      <div className="flex">
        <button
          className="bg-[#4CAF50] text-white px-4 py-2 ml-4 rounded"
          onClick={acceptCookies}
        >
          Accept
        </button>
      </div>
    </motion.div>
  );
};

export default CookieConsent;
