
import SEO from '../components/common/seo/SEO';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull'; // Import AppHeaderFull

const ResourceLibrary = () => {
  return (
    <>
      <SEO
        title='Resource Library'
        description="Get answers to questions about the notary process." 
        includeOrg={false}
      />
      <div className="p-8 bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col">
        {/* Header Section */}
        <header className=" fixed top-0 left-0 right-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />  {/* Use AppHeaderFull for desktop */}
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <a href="/" className="text-[#FFFFFC] text-xl font-bold mr-auto">Forte Notary</a>
            <AppHeaderMobile />  {/* Use AppHeaderMobile for mobile */}
          </div>
        </header>
        {/* Main Content Section */}
        <main>
          <div className="flex-grow flex items-center justify-center">
            <div className="pricing-card bg-[#212A37] mt-8 p-8 max-w-lg w-full">
              <h1 className="text-3xl font-bold mb-6">Resources Library</h1>
              <h2>Browse resources that can help you learn about the notarization process</h2>
              <p className="mb-4">The resources library is a work in progress</p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ResourceLibrary;
