
import SEO from '../components/common/seo/SEO';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull'; // Import AppHeaderFull

const Mobile = () => {
  return (
    <>
      <SEO
        title='DFW Mobile Notary'
        description="Here is our contact information. Call us to place an order." 
        includeOrg={true}
      />
      <div className="pricing-page p-8 bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col">
        {/* Header Section */}
        <header className="w-full p-4 flex justify-between items-center border-b border-[#FFFFFC]">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />  {/* Use AppHeaderFull for desktop */}
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <a href="/" className="text-[#FFFFFC] text-xl font-bold mr-auto">Forte Notary</a>
            <AppHeaderMobile />  {/* Use AppHeaderMobile for mobile */}
          </div>
        </header>
        {/* Main Content Section */}
        <main>
          <div className="flex-grow flex items-center justify-center">
            <div className="pricing-card bg-[#212A37] p-8 max-w-lg w-full">
              <h1 className="text-3xl font-bold mb-6">Mobile Notary</h1>
              <h2>Why is a mobile notary good for you?</h2>
              <p className="mb-4">If you are looking for a mobile notary in the Dallas-Fort Worth area, we can help. Forte Notary provides mobile notary services 24/7</p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Mobile;
