
export interface SchemaOrgType {
    "@context": string;
    "@type": string;
    name: string;
    url: string;
    logo: string;
    telephone: string;
    address: {
      "@type": string;
      streetAddress: string;
      addressLocality: string;
      addressRegion: string;
      postalCode: string;
      addressCountry: string;
    };
    areaServed: string[];
    openingHours: string;
    sameAs: string[];
  }


// src/components/common/seo/schemaOrg.ts
import areaServed from './areaServed';

const schemaOrgJSONLD = {
  "@context": "https://schema.org",
  "@type": "Notary",
  "name": "Forte Notary",
  "url": "https://fortenotary.com",
  "logo": "https://fortenotary.com/logo.png",
  "telephone": "+1-214-662-9169", // Update with your actual phone number
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "5013 Madison Drive", // Update with your actual address
    "addressLocality": "Frisco",
    "addressRegion": "TX",
    "postalCode": "75035",
    "addressCountry": "US"
  },
  "areaServed": areaServed,
  "openingHours": "Mo-Su 00:00-23:59",
  "sameAs": [
    "https://www.facebook.com/fortenotary",
    "https://www.linkedin.com/company/fortenotary"
  ]
};

export default schemaOrgJSONLD;
