import { FaBars, FaSearch } from 'react-icons/fa';
import { useState } from 'react';
import Submenu from './Submenu';

function AppHeaderMobile() {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleSubmenuOpen = () => {
    setIsSubmenuOpen(true);
  };

  const handleSubmenuClose = () => {
    setIsSubmenuOpen(false);
  };

  return (
    <>
      {/* <FaSearch className="text-[#FFFFFC] mr-4" /> */}
      <FaBars className="text-[#FFFFFC]" onClick={handleSubmenuOpen} />

      {/* Submenu Component */}
      <Submenu isOpen={isSubmenuOpen} onClose={handleSubmenuClose} title="Menu" />
    </>
  );
}

export default AppHeaderMobile;