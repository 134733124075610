import { useState } from 'react';
import { css } from '@emotion/css'
import ResourcePanel from './ResourcePanel';

const ResourceStyle = css`

.colorlink {
    color: #FFFFFC;
    position: relative; /* Ensure pseudo-element is positioned relative to this */
    padding-right: 12px; /* Space for the arrow */

}

.colorlink::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(45deg); /* Properly center and rotate the arrow */
    width: 6px;
    height: 6px;
    border-right: 2px solid #FFFFFC;
    border-bottom: 2px solid #FFFFFC;
    transition: transform 0.1s ease-in-out;
  }


`;

function AppHeaderFull() {
    const [isPanelVisible, setIsPanelVisible] = useState(false);

    const handleClick = () => {
        setIsPanelVisible(!isPanelVisible); // Toggle the panel visibility
    };

    return (
    <>

        {/* <div className="hidden md:block">
        </div> */}

        {/* className={ResourceStyle} */}

        <nav className={`flex w-full items-center justify-between ${ResourceStyle}`} >
            <div className="lhs">
                <a href="/" className="text-[#FFFFFC] text-2xl font-bold">Forte Notary</a>
            </div>
            
            <div className="rhs flex items-center space-x-8">
                <a href="/pricing" className="text-[#FFFFFC] hover:text-[#e2ba57]">Pricing</a>
                <a href="/ralphbrooks" className="text-[#FFFFFC] hover:text-[#e2ba57]">About Forte</a>
                <span>
                    <span className="colorlink cursor-pointer" 
                        onClick={handleClick}
                    >
                        Resources
                    </span>
                    <ResourcePanel isVisible={isPanelVisible} />
                </span>
                {/* <span className="mx-4"></span>  */}
                <a href="/contact" className="text-[#FFFFFC] hover:text-[#e2ba57]">Contact Us</a>
            </div>
        </nav>

    </>
    );
    }

export default AppHeaderFull;