import { motion } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import ReactDOM from 'react-dom'; // Import ReactDOM

interface ResourcesMobileMenuProps {
  isOpen: boolean;
  onBack: () => void;
}

const ResourcesMobileMenu: React.FC<ResourcesMobileMenuProps> = ({ isOpen, onBack }) => {
  const menuContent = (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: isOpen ? '0%' : '100%' }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 30,
        duration: 0.2,
        ease: 'easeOut',
      }}
      className="fixed inset-0 w-full h-full bg-[#1E2A3A] z-[1100] isolate"
      style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
    >
      <button
        onClick={onBack}
        className="absolute top-4 left-4 text-[#0084ff] text-xl"
        aria-label="Back"
      >
        <FaArrowLeft />
      </button>

      <div className="text-white text-2xl font-bold p-4 pl-12">Resources</div>

      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2, duration: 0.2 }}
        className="flex flex-col items-start p-8 text-white"
      >
        <a href="/resources" className="mb-4 pl-12">Resources Library</a>
        <a href="/blog" className="pl-12">Blog</a>
      </motion.div>
    </motion.div>
  );

  return isOpen
    ? ReactDOM.createPortal(menuContent, document.body)
    : null;
};

export default ResourcesMobileMenu;
