
import { css } from '@emotion/css';

interface VideoEmbedProps {
  videoId: string;
}

const VideoEmbedStyle = css`

  .video-responsive {
    display: flex;
    width: 100%;
    max-width: 850px;
    height: 600px;
    /* overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 200px; */
  }

  .ifrmpad {
    /* position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; */
  }

  .ytiframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

`

const VideoEmbed: React.FC<VideoEmbedProps> = ({ videoId }) => {
  return (
    <div className={`${VideoEmbedStyle} video-responsive`}>
      <div className="ifrmpad">
        <p>
          <iframe
            className='ytiframe'
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
            width="850"
            height="300"
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </p>
      </div>
    </div>
  );
};

export default VideoEmbed;
