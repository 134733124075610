import { Helmet } from 'react-helmet';
import SEO from './components/common/seo/SEO';
import AppHeaderFull from './components/header/AppHeaderFull';
import AppHeaderMobile from './components/header/AppHeaderMobile';
import KeyServices from './components/frontpage/keyservices';
import YoutubePlaceholder from './components/common/YoutubePlaceholder';
import { FaClock, FaMobileAlt, FaCheckCircle } from 'react-icons/fa';


function getPageMeta() {
  let elements: JSX.Element[] = [];
  const descriptionElement = <meta name="description" content="We are a mobile notary based out of Frisco, TX." />;
  elements.push(descriptionElement);
  return elements;
}

function App() {
  return (
    <>
      <SEO
        title='Forte Notary | Mobile Notary in DFW'
        description="Notarization made easy with simple pricing. We are a mobile notary based out of Frisco, TX." 
        includeOrg={true}
      />
      <div className="app-page pt-20 p-8 bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col">
        {/* Header Section */}
        <header className="fixed top-0 left-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur z-[100]">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <a href="/" className="text-[#FFFFFC] text-xl font-bold mr-auto">Forte Notary</a>
            <AppHeaderMobile />
          </div>
        </header>

        {/* New Section: Fast Error-Free Signings */}
        <section className="mt-24 mb-12 text-center">
          <h2 className="text-3xl md:text-5xl font-bold text-[#FFFFFC]">
            Fast, Error-Free Signings in DFW
          </h2>
          <p className="mt-4 text-xl text-[#FFFFFC] max-w-3xl mx-auto">
            Leveraging cutting-edge technology to provide professional notary services for busy professionals on the move.
          </p>
        </section>

        {/* Main Content Section */}
        <div className="flex-grow flex flex-col items-center justify-center gap-16">
          {/* Youtube Placeholder */}
            <div className="appplaceholder mt-6 w-full my-auto">
              <YoutubePlaceholder
                videoId='ertMlob8lqE'
              />
            </div>
          {/* Icons Section */}
          <section className="mt-12 flex flex-col md:flex-row justify-center items-center gap-8">
            {/* 24/7 Availability */}
            <div className="flex flex-col items-center">
              <FaClock className="text-[#e2ba57] text-6xl mb-4" />
              <h3 className="text-2xl font-semibold">24/7 Availability</h3>
              <p className="text-center mt-2 max-w-xs">
                Always ready when you need us, ensuring prompt notarization without delays.
              </p>
            </div>
            {/* Mobile Convenience */}
            <div className="flex flex-col items-center">
              <FaMobileAlt className="text-[#e2ba57] text-6xl mb-4" />
              <h3 className="text-2xl font-semibold">We Come to You</h3>
                <p className="text-center mt-2 max-w-xs">We travel anywhere in the Dallas Fort Worth Metroplex to provide notary services.</p>
            </div>
            {/* Error-Free Signings */}
            <div className="flex flex-col items-center">
              <FaCheckCircle className="text-[#e2ba57] text-6xl mb-4" />
              <h3 className="text-2xl font-semibold">Error-Free Signings</h3>
              <p className="text-center mt-2 max-w-xs">
                Precision and professionalism to ensure your documents are notarized correctly.
              </p>
            </div>
          </section>



          {/* Key Services Section */}
          <KeyServices />
        </div>
      </div>
    </>
  );
}

export default App;