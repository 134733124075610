// src/components/common/seo/SEO.tsx
import { Helmet } from 'react-helmet';
import schemaOrgJSONLD, { SchemaOrgType } from './schemaOrg';

interface SEOProps {
  title?: string;
  description?: string;
  includeOrg?: boolean; // Include contact information for organization
}

const SEO: React.FC<SEOProps> = ({ title, description, includeOrg }) => {

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {includeOrg && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD as SchemaOrgType)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
