import { css } from '@emotion/css';
import { useState } from 'react';

const panelStyle = css`
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0.3s;

  &.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
`;

const linkStyle = css`
  display: block;
  margin-bottom: 10px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ResourcePanel = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className={`${panelStyle} ${isVisible ? 'visible' : ''}`}>
      <a href="/resources" className={linkStyle}>
        Resources Library
      </a>
    </div>
  );
};

export default ResourcePanel;
