// src/data/areaServed.ts
const areaServed = [
    "Frisco",
    "Plano",
    "McKinney",
    "Allen",
    "Richardson",
    "Garland",
    "Carrollton",
    "Lewisville",
    "Denton",
    "Dallas",
    "Fort Worth",
    "Irving",
    "Arlington",
    "Addison",
    "The Colony",
    "Prosper",
    "Little Elm",
    "Celina",
    "Wylie",
    "Sachse",
    "Murphy",
    "Rowlett",
    "Mesquite",
    "Flower Mound",
    "Grapevine",
    "Coppell",
    "Southlake",
    "Keller",
    "Farmers Branch",
    "Hebron",
    "Lucas",
    "Fairview",
    "Princeton",
    "Anna",
    "Melissa",
    "Aubrey",
    "Pilot Point",
    "Roanoke",
    // Add more cities as needed
  ];
  
  export default areaServed;
  